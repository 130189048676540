import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import Logo from './logo.png';
import Chatbot from './chatbot.png';  // Import chatbot profile picture

function App() {
    const [question, setQuestion] = useState('');
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);

    const handleAsk = async () => {
        if (!question) return;

        const userMessage = { text: question, type: 'user' };
        setMessages((prev) => [...prev, userMessage]);
        setQuestion('');

        try {
            const res = await axios.post(`https://slimdigisapi.brandcorridor.lk/ask`, { question });
            const botMessageText = res.data.answer;

            const botMessage = { text: botMessageText, type: 'bot' };
            setMessages((prev) => [...prev, botMessage]);
        } catch (error) {
            console.error('Error asking question:', error);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAsk();
        }
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <div className="chat-container">
            <div className="header-container">
                <img src={Logo} alt="Logo" className="logo" />
                <h1>SLIM DIGIS 2.4</h1>
            </div>
            <div className="messages-container">
                {messages.map((msg, index) => (
                    <div key={index} className={`message ${msg.type}`}>
                        {msg.type === 'bot' ? (
                            <div className="bot-message">
                                <img src={Chatbot} alt="Chatbot" className="chatbot-avatar" />
                                <div className="bot-text">
                                    {msg.text.split('\n').map((line, i) => (
                                        <span key={i}>
                                            {line.includes('**') ? (
                                                <strong>{line.replace(/\*\*/g, '')}</strong>
                                            ) : (
                                                line
                                            )}
                                            <br />
                                        </span>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="user-text">
                                {msg.text}
                            </div>
                        )}
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Ask a question about the event..."
                    className="input"
                />
                <button onClick={handleAsk} className="ask-button">Ask</button>
            </div>
        </div>
    );
}

export default App;
